import { type ReactNode, useState, useCallback } from 'react';

import * as Collapsible from '@radix-ui/react-collapsible';

import { styled, keyframes } from '@parsec/styles';

import Icon from '../Icon';

// STYLES
const slideDown = keyframes({
  from: {
    height: 0
  },
  to: {
    height: `var(--radix-collapsible-content-height)`
  }
});

const slideUp = keyframes({
  from: {
    height: `var(--radix-collapsible-content-height)`
  },
  to: {
    height: 0
  }
});

const ArrowIcon = styled(Icon, {
  transition: 'transform 125ms ease-in-out',
  variants: {
    open: {
      true: {
        transform: 'rotate(180deg)'
      },
      false: {}
    }
  }
});

const CollapsibleRoot = styled(Collapsible.Root, {
  width: '100%',
  borderTop: '0.1rem solid $pancham',
  paddingTop: '3.2rem',
  fontFamily: '$newDefault'
});

const CollapsibleTrigger = styled(Collapsible.Trigger, {
  fontFamily: '$newDefault',
  width: 'fit-content',
  fontSize: '$newInfo',
  lineHeight: '$info',
  color: '$consoleWhite',
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
  cursor: 'pointer',
  gridArea: 'button',
  justifySelf: 'end'
});

const CollapsibleContent = styled(Collapsible.Content, {
  paddingTop: '3.2rem',
  "&[data-state='open']": {
    animation: `${slideDown} 300ms ease-out`
  },
  "&[data-state='closed']": {
    animation: `${slideUp} 300ms ease-out`
  }
});

const Wrapper = styled('div', {
  display: 'grid',
  rowGap: '$large',
  gridTemplateColumns: 'auto 7.6rem',
  gridTemplateAreas: "'legend button' 'description description'"
});

const Legend = styled('legend', {
  fontSize: '$body',
  lineHeight: '$attribution',
  fontWeight: '$bold',
  gridArea: 'legend'
});

const Description = styled('p', {
  fontSize: '$newInfo',
  lineHeight: '$info',
  gridArea: 'description'
});

export interface AppRuleFieldsetProps extends Collapsible.CollapsibleProps {
  children: ReactNode;
  formId: string;
  title: string;
  description?: string;
}

export function AppRuleFieldsetCollapsible({
  children,
  formId,
  title,
  description,
  defaultOpen = false,
  onOpenChange
}: AppRuleFieldsetProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      onOpenChange?.(open);
      setIsOpen(open);
    },
    [onOpenChange]
  );

  return (
    <CollapsibleRoot open={isOpen} onOpenChange={handleOpenChange}>
      <fieldset form={formId} name={title}>
        <Wrapper>
          <Legend>{title}</Legend>
          <CollapsibleTrigger>
            {isOpen ? 'Collapse' : 'Expand'}
            <ArrowIcon name="caret" open={isOpen} />
          </CollapsibleTrigger>
          {description ? <Description>{description}</Description> : null}
        </Wrapper>
        <CollapsibleContent>{children}</CollapsibleContent>
      </fieldset>
    </CollapsibleRoot>
  );
}
