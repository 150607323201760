import { useFormContext } from 'react-hook-form';

// @parsec
import { styled } from '@parsec/styles';

// components
import FieldLabel from '../FieldLabel';
import Input from '../Input';
import {
  PasswordInputToggled,
  PasswordInputToggledProps
} from '../PasswordInputToggled';

export interface CredentialFormProps {
  className?: string;
  showTfaInput?: boolean;
  passwordInputName?: string;
  tfaInputName?: string;
}

function CredentialForm(
  props: CredentialFormProps & Pick<PasswordInputToggledProps, 'onToggleShow'>
) {
  const {
    className,
    showTfaInput,
    passwordInputName = 'password',
    tfaInputName = 'tfa',
    ...rest
  } = props;

  const { register } = useFormContext();

  return (
    <Wrapper className={className} showTfa={showTfaInput}>
      <FieldLabel>
        <FieldLabel.Label label="Password">
          <PasswordInputToggled
            autoComplete="off"
            {...register(passwordInputName)}
            {...rest}
          />
        </FieldLabel.Label>
      </FieldLabel>
      {showTfaInput ? (
        <FieldLabel>
          <FieldLabel.Label label="2FA Code">
            <Input
              type="numeric"
              {...register(tfaInputName, { valueAsNumber: true })}
            />
          </FieldLabel.Label>
        </FieldLabel>
      ) : null}
    </Wrapper>
  );
}

export default CredentialForm;

const Wrapper = styled('div', {
  display: 'grid',
  gap: '$xlarge',
  variants: {
    showTfa: {
      true: { gridTemplate: 'auto / 2fr 1fr' }
    }
  }
});
